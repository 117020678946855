import React from 'react'
import img from '../../images/sonoforeza.webp'
import {Link} from 'gatsby'
import Layout from '../../components/Layout'
import {Fade} from 'react-reveal'
import {Seo} from '../../components/Seo'

const Sonoforeza = () => {
  return (
    <>
      <Layout>
        <Seo
          title='Sonoforeza | Modelowanie Sylwetki'
          description='Sonoforeza to zabieg kosmetyczny wykorzystujący ultradźwięki. Z tego
          względu jest on określany mianem „masażu ultradźwiękowego” lub
          „mikromasażu”.'
          url='https://modelowaniesylwetki-wawer.pl/zabiegi-na-twarz/sonoforeza'
        />

        <div className='page-container'>
          <div className='page'>
            <div className='content-1'>
              <h1>Sonoforeza</h1>
              <div className='section'>
                <div className='image-container'>
                  <div style={{paddingTop: '66%'}}></div>
                  <img
                    src={img}
                    width='1000px'
                    height='667px'
                    style={{border: '10px solid white'}}
                    alt='sonoforeza zdjęcie'
                  ></img>
                </div>
                <div className='text'>
                  <p>
                    Sonoforeza to zabieg kosmetyczny wykorzystujący ultradźwięki. Z tego
                    względu jest on określany mianem „masażu ultradźwiękowego” lub
                    „mikromasażu”. Ultradźwięki (fale dźwiękowe o wysokiej częstotliwości)
                    poprawiają przepuszczalność błon komórkowych i ułatwiają substancjom
                    odżywczym zawartym w preparatach kosmetycznych przeniknięcie w
                    głębokie warstwy skóry. Dzięki Sonoforezie, Twoja cera będzie
                    rozświetlona, odżywiona, dotleniona i nawilżona. Poprawi się jej
                    elastyczność, nastąpi redukcja blizn i spłycenie zmarszczek.
                  </p>
                </div>
              </div>
            </div>
            <Fade left>
              <Link to='/kontakt'>
                <button>Umów Wizytę</button>
              </Link>
            </Fade>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Sonoforeza
